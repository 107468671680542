import * as React from 'react';
import { AnswerObjectionsSection } from '../../../components/AnswerObjectionsSection';
import { BenefitsList } from '../../../components/BenefitsList';
import { FinalCTA } from '../../../components/FinalCTA';
import { HeroSection } from '../../../components/HeroSection';
import { Integrations } from '../../../components/Integrations';
import { Layout } from '../../../components/Layout';
import { PricingSection } from '../../../components/PricingSection';
import { QRScanSection } from '../../../components/QRScanSection';
import { SEO } from '../../../components/SEO';
import { TestimonialSection } from '../../../components/TestimonialSection';
import { BenefitsSection } from '../../../components/pages/features/tfnd/BenefitsSection';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

const benefits = [
  {
    heading: 'Easy setup',
    body: 'Setup in 10 minutes and avoid costly implementations. Canyou integrates with your existing systems so you can keep using your existing payroll and time systems.',
  },
  {
    heading: 'Easy for the team',
    body: 'Team members use Canyou from their own device. Set reminders with Canyou to send instant notifications when the team needs to interact.',
  },
  {
    heading: 'Everything in one spot',
    body: 'Canyou is the single place for your team to keep track of the status of training, compliance and operations tasks',
  },
  {
    heading: 'Reminders and notifications',
    body: 'Setup reminders and notifications to keep track of important dates like licence expiries or annual pay reviews. Notifications get sent out to the team members to make sure you never miss anything',
  },
  {
    heading: 'Safe and secure',
    body: 'Transfer sensitive data safely and securely. No more data leak risks through emails and text messages',
  },
];

type Data = {
  heroImage: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const TFND: React.FC = () => {
  const { heroImage } = useStaticQuery<Data>(
    graphql`
      {
        heroImage: file(
          relativePath: {
            eq: "screenshots/tax-file-number-declaration-software.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(width: 900, layout: CONSTRAINED)
          }
        }
      }
    `,
  );
  return (
    <Layout>
      <SEO
        title="Electronic Tax File Number Declaration with Xero integration"
        description="Remove busy-work from your employee onboarding process by automating the collection and synchronisation of employee Tax File Numbers."
      />
      <HeroSection
        heading="Electronic Tax File Number Declaration"
        subHeading="Remove busy-work from your employee onboarding process by automating the collection and synchronisation of employee Tax File Numbers. Employees can submit tax file numbers from their own device eliminating paper, emails and text messages."
        heroImage={
          <GatsbyImage
            alt="tax file number declaration software"
            image={heroImage.childImageSharp.gatsbyImageData}
            style={{
              maxWidth: `90vh`,
              filter: 'drop-shadow(0px 24px 32px #000F2F14)',
            }}
          />
        }
      />
      <Integrations isMyobLogoDisplayed={false} bgColor="brand.egg" />
      <BenefitsSection />
      <BenefitsList benefits={benefits} />
      <TestimonialSection />
      <PricingSection />
      <AnswerObjectionsSection />
      <QRScanSection />
      <FinalCTA />
    </Layout>
  );
};

export default TFND;
