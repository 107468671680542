import { Heading, Box } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { BenefitDetails, BenefitSection } from '../../../BenefitSection';

type Data = {
  tfndForm: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  tfndStatus: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  qrScan: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  qrPhone: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  twoFA: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  xeroSync: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export const BenefitsSection: React.FC = () => {
  const { tfndForm, tfndStatus, qrScan, qrPhone, twoFA, xeroSync } =
    useStaticQuery<Data>(
      graphql`
        {
          tfndForm: file(
            relativePath: {
              eq: "screenshots/tax-file-number-declaration-form.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
            }
          }
          tfndStatus: file(
            relativePath: {
              eq: "screenshots/tax-file-number-declaration-employee-status.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 280, layout: CONSTRAINED, quality: 90)
            }
          }
          qrScan: file(relativePath: { eq: "features/qr-code-live-test.png" }) {
            childImageSharp {
              gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
            }
          }
          qrPhone: file(
            relativePath: { eq: "screenshots/qr-code-employee-device.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
            }
          }
          twoFA: file(
            relativePath: { eq: "screenshots/two-factor-authentication.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 170, layout: CONSTRAINED, quality: 90)
            }
          }
          xeroSync: file(
            relativePath: { eq: "screenshots/xero-integration-synchronise.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 360, layout: CONSTRAINED, quality: 90)
            }
          }
        }
      `,
    );

  const benefitDetails: BenefitDetails[] = [
    {
      heading: 'No more paper, emails or texts',
      subHeading:
        'Digital completion via the employees device with automatic validations',
      body: "It's hard to avoid errors with paper forms. Missing fields, illegible hand writing and constant checking of the inbox. With Canyou Tax File Number Declarations can be submitted via any device with a web browser. We check and validate every piece of information so that when you get the form back you can feel confident it's correct.",
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData: tfndForm.childImageSharp.gatsbyImageData,
          alt: 'Tax file number declaration form',
        },
        {
          gatsbyImageData: tfndStatus.childImageSharp.gatsbyImageData,
          alt: 'Tax file number declaration status',
        },
      ],
    },
    {
      heading: 'Easy for employees',
      subHeading:
        'No passwords required. Scan a QR code to get started or be invited by the business',
      body: "Employees can scan a QR code to get started and complete the Tax File number declaration from any device with an internet browser. Employees don't need to setup a password to get started making the process  streamlined and user friendly.",
      images: [
        {
          gatsbyImageData: qrScan.childImageSharp.gatsbyImageData,
          alt: 'QR code for employees',
        },
        {
          gatsbyImageData: qrPhone.childImageSharp.gatsbyImageData,
          alt: 'Scan QR code from employee device',
        },
      ],
    },
    {
      heading: 'Secure Information Transfer',
      subHeading: 'Remove the risk of data leaks via email and text message',
      body: 'Transferring tax file numbers via email and text has security risks. Canyou allows employees to securely transmit their information via a safe and secure method. 2 factor authentication ensures that the information is only viewed by the authorised users. All Canyou data is stored in Australia.',
      isOrderReversed: true,
      images: [
        {
          gatsbyImageData: twoFA.childImageSharp.gatsbyImageData,
          alt: 'Two factor authentication check',
        },
      ],
    },
    {
      heading: 'Save time with powerful integrations',
      subHeading: 'Single click to sync employee data into Xero and Deputy',
      body: "Remove double entry and errors from your employee onboarding process. Canyou checks and validates employee information through each step so that it's as easy as a single click to sync data with Xero and Deputy.",
      images: [
        {
          gatsbyImageData: xeroSync.childImageSharp.gatsbyImageData,
          alt: 'Xero and Deputy integration employee synchronise',
        },
      ],
    },
  ];

  return (
    <Box pb={{ base: '35px', lg: '120px' }} bg="brand.egg">
      <Heading
        as="h2"
        size="xl"
        textAlign="center"
        px={4}
        pt={{ base: '25px', lg: '115px' }}
        pb={{ base: '55px', lg: '140px' }}
      >
        <Box as="span" color="brand.blue">
          Easy and secure{' '}
        </Box>
        <Box as="span">
          collection <br />
          of employee tax file numbers
        </Box>
      </Heading>
      {benefitDetails.map(
        ({ heading, body, images, subHeading, isOrderReversed }) => (
          <BenefitSection
            key={heading}
            heading={heading}
            body={body}
            images={images}
            subHeading={subHeading}
            isOrderReversed={isOrderReversed}
          />
        ),
      )}
    </Box>
  );
};
